import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import App from './App';
import ErrorFallback from './components/ErrorFallback';
import './index.css';

// Initialize analytics
const initAnalytics = () => {
  if (process.env.NODE_ENV === 'production') {
    // Add your analytics initialization here
    console.log('Analytics initialized');
  }
};

initAnalytics();

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <HelmetProvider>
        <App />
        <Toaster position="top-right" />
      </HelmetProvider>
    </ErrorBoundary>
  </StrictMode>
);