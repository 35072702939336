import React from 'react';
import { ChevronDown } from 'lucide-react';

export default function Hero() {
  const scrollToDesign = () => {
    document.getElementById('design-section')?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
      {/* Video Background */}
      <div className="absolute inset-0 w-full h-full">
        <video
          autoPlay
          loop
          muted
          playsInline
          className="absolute w-full h-full object-cover"
          poster="/video-poster.jpg"
        >
          <source
            src="https://seshproducts.com/cdn/shop/videos/c/vp/7265ac87c23b406aada071135fc6a7f7/7265ac87c23b406aada071135fc6a7f7.HD-1080p-7.2Mbps-34941365.mp4?v=0"
            type="video/mp4"
          />
        </video>
        <div className="absolute inset-0 bg-gradient-to-b from-black/60 via-black/50 to-black/70" />
      </div>
      
      <div className="relative z-10 text-center px-4 max-w-4xl mx-auto">
        <h1 className="text-5xl md:text-7xl font-bold text-white mb-6 tracking-tight">
          Customize Your{' '}
          <span className="bg-gradient-to-r from-blue-400 to-cyan-300 text-transparent bg-clip-text">
            Performance Pouches
          </span>
        </h1>
        
        <p className="text-xl md:text-2xl text-blue-100 mb-12 leading-relaxed">
          Upload your image, add text, and bring your vision to life with custom 
          performance-enhancing pouches.
        </p>

        <button
          onClick={scrollToDesign}
          className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white px-8 py-4 rounded-full
            text-lg font-semibold transform transition-all hover:scale-105 hover:shadow-xl
            hover:from-cyan-400 hover:to-blue-400"
        >
          Start Designing Now
        </button>

        <div className="absolute bottom-8 left-1/2 -translate-x-1/2 animate-bounce">
          <ChevronDown size={32} className="text-white/80" />
        </div>
      </div>
    </div>
  );
}