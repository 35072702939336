import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-gray-900 py-4 px-4 text-center">
      <p className="text-xs text-gray-500">
        Made with love by Kyler in California
      </p>
    </footer>
  );
}