import React, { useState, Suspense } from 'react';
import { Upload } from 'lucide-react';
import toast from 'react-hot-toast';
import { useImageUpload } from '../hooks/useImageUpload';
import LoadingSpinner from './LoadingSpinner';

const PuckPreview = React.lazy(() => import('./PuckPreview'));

const PLASTIC_COLORS = {
  White: '#FFFFFF',
  Black: '#000000',
  'Royal Blue': '#2563EB',
  'Emerald Green': '#059669',
  'Ruby Red': '#DC2626',
};

export default function Designer() {
  const { image, isLoading: imageLoading, error: imageError, handleImageUpload } = useImageUpload();
  const [text, setText] = useState('');
  const [font, setFont] = useState('font-sans');
  const [textColor, setTextColor] = useState('#FFFFFF');
  const [plasticColor, setPlasticColor] = useState('#FFFFFF');

  const handlePurchase = () => {
    if (!image && !text) {
      toast.error('Please add an image or text to your design');
      return;
    }
    // Add your checkout logic here
    toast.success('Proceeding to checkout...');
  };

  return (
    <div 
      id="design-section" 
      className="min-h-screen bg-gradient-to-br from-gray-900 to-blue-900 py-20 px-4"
      aria-labelledby="design-heading"
    >
      <div className="max-w-6xl mx-auto">
        <div className="grid md:grid-cols-2 gap-12">
          {/* Design Controls */}
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8 space-y-8">
            <h2 id="design-heading" className="text-3xl font-bold text-white mb-8">
              Customize Your Puck
            </h2>

            {/* Plastic Color Selection */}
            <div className="space-y-4">
              <label id="color-label" className="block text-lg font-medium text-blue-200">
                Plastic Color
              </label>
              <div 
                role="radiogroup" 
                aria-labelledby="color-label"
                className="flex flex-wrap gap-3"
              >
                {Object.entries(PLASTIC_COLORS).map(([name, color]) => (
                  <button
                    key={color}
                    onClick={() => setPlasticColor(color)}
                    className={`w-12 h-12 rounded-full border-2 transition-all ${
                      plasticColor === color ? 'border-blue-400 scale-110' : 'border-transparent'
                    }`}
                    style={{ backgroundColor: color }}
                    aria-label={`Select ${name}`}
                    aria-pressed={plasticColor === color}
                  />
                ))}
              </div>
            </div>

            {/* Image Upload */}
            <div className="space-y-4">
              <label className="block text-lg font-medium text-blue-200">Upload Image</label>
              <div 
                className={`border-2 border-dashed rounded-lg p-8 text-center
                  ${imageError ? 'border-red-400/50' : 'border-blue-400/50'}
                  ${imageLoading ? 'opacity-50' : ''}`}
              >
                <input
                  type="file"
                  accept="image/jpeg,image/png"
                  onChange={handleImageUpload}
                  className="hidden"
                  id="image-upload"
                  disabled={imageLoading}
                  aria-label="Upload image"
                />
                <label
                  htmlFor="image-upload"
                  className="cursor-pointer flex flex-col items-center space-y-4"
                >
                  {imageLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <Upload className="w-12 h-12 text-blue-300" />
                      <span className="text-blue-200">
                        Drag & drop or click to upload (Max 5MB)
                      </span>
                    </>
                  )}
                </label>
                {imageError && (
                  <p className="text-red-400 mt-2 text-sm" role="alert">
                    {imageError}
                  </p>
                )}
              </div>
            </div>

            {/* Text Customization */}
            <div className="space-y-4">
              <label htmlFor="puck-text" className="block text-lg font-medium text-blue-200">
                Add Text
              </label>
              <input
                type="text"
                id="puck-text"
                maxLength={50}
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="w-full bg-white/10 border border-blue-400/30 rounded-lg px-4 py-3 text-white"
                placeholder="Enter your text here..."
                aria-label="Puck text"
              />
              <p className="text-sm text-blue-200">
                {text.length}/50 characters
              </p>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label htmlFor="font-select" className="block text-sm text-blue-200 mb-2">
                    Font Style
                  </label>
                  <select
                    id="font-select"
                    value={font}
                    onChange={(e) => setFont(e.target.value)}
                    className="w-full bg-white/10 border border-blue-400/30 rounded-lg px-4 py-2 text-white"
                  >
                    <option value="font-sans">Sans Serif</option>
                    <option value="font-serif">Serif</option>
                    <option value="font-mono">Monospace</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="text-color" className="block text-sm text-blue-200 mb-2">
                    Text Color
                  </label>
                  <input
                    type="color"
                    id="text-color"
                    value={textColor}
                    onChange={(e) => setTextColor(e.target.value)}
                    className="w-full h-10 rounded-lg cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Preview */}
          <div className="bg-white/10 backdrop-blur-lg rounded-2xl p-8">
            <h2 className="text-3xl font-bold text-white mb-8">Preview</h2>
            <Suspense fallback={<LoadingSpinner />}>
              <PuckPreview
                imageUrl={image}
                text={text}
                textColor={textColor}
                font={font}
                plasticColor={plasticColor}
              />
            </Suspense>
            <p className="text-blue-200 text-sm mt-4 text-center">
              Drag to rotate the puck and see your design from different angles
            </p>
          </div>
        </div>

        {/* Purchase Button */}
        <div className="mt-12 text-center">
          <button
            onClick={handlePurchase}
            className="bg-gradient-to-r from-green-400 to-blue-500 text-white px-12 py-4 rounded-full
              text-xl font-bold transform transition-all hover:scale-105 hover:shadow-xl
              hover:from-green-300 hover:to-blue-400 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-blue-500"
          >
            Buy Now - $49.99
          </button>
        </div>
      </div>
    </div>
  );
}