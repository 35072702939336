import { useState } from 'react';
import toast from 'react-hot-toast';

interface UseImageUploadResult {
  image: string | null;
  isLoading: boolean;
  error: string | null;
  handleImageUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function useImageUpload(): UseImageUploadResult {
  const [image, setImage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setError(null);

    if (!file) {
      return;
    }

    // Validate file size (5MB limit)
    if (file.size > 5 * 1024 * 1024) {
      setError('Image size must be less than 5MB');
      toast.error('Image size must be less than 5MB');
      return;
    }

    // Validate file type
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      setError('Only JPEG and PNG images are allowed');
      toast.error('Only JPEG and PNG images are allowed');
      return;
    }

    setIsLoading(true);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result as string);
      setIsLoading(false);
      toast.success('Image uploaded successfully');
    };

    reader.onerror = () => {
      setError('Failed to read image file');
      setIsLoading(false);
      toast.error('Failed to read image file');
    };

    reader.readAsDataURL(file);
  };

  return { image, isLoading, error, handleImageUpload };
}