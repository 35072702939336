import React from 'react';
import Hero from './components/Hero';
import Designer from './components/Designer';
import Footer from './components/Footer';

function App() {
  return (
    <main className="min-h-screen bg-gray-900">
      <Hero />
      <Designer />
      <Footer />
    </main>
  );
}

export default App;